import React, { forwardRef } from "react";
import TransitionLink from "gatsby-plugin-transition-link";
import gsap from "gsap";

const TransitionOUT = ({ exit: { length }, node }) => {
  gsap.timeline().fromTo(
    node,
    length,
    {
      opacity: 1
    },
    {
      opacity: 0
    }
  );
};

const TransitionIN = ({ exit: { length }, node }) => {
  gsap.timeline().fromTo(
    node,
    length,
    {
      opacity: 0
    },
    {
      opacity: 1
    }
  );
};

const TransitionLinkFade = forwardRef((props, ref) => (
  <TransitionLink
    preventScrollJump
    ref={ref}
    exit={{
      length: 0.5,
      zIndex: 999,
      trigger: ({ exit, node }) => TransitionOUT({ exit, node })
    }}
    entry={{
      length: 0.5,
      trigger: ({ exit, node }) => TransitionIN({ exit, node })
    }}
    {...props}
  >
    {props.children}
  </TransitionLink>
));

export default TransitionLinkFade;
