import { Router } from "@reach/router";
import slug from "limax";
import React, { useState } from "react";
import GallerySingle from "./gallery-single";

export default function GalleryFullscreen({ pageContext, location }) {
  const [pageColor, setPageColor] = useState("white");
  const images = pageContext.node.data.images;
  const { uid } = pageContext;

  const length = images.length - 1;
  const nextIndex = index => (index === length ? 0 : index + 1);
  const prevIndex = index => (index === 0 ? length : index - 1);
  const prevLink = index => {
    const imageField = images[prevIndex(index)];
    return `/${uid}/${slug(imageField.title1)}-${
      imageField.image.localFile.id
    }`;
  };
  const nextLink = index => {
    const imageField = images[nextIndex(index)];
    return `/${uid}/${slug(imageField.title1)}-${
      imageField.image.localFile.id
    }`;
  };

  return (
    <Router basepath={`/${uid}/`}>
      {images.map((image, index) => {
        const imageSlug = `${slug(image.title1)}-${image.image.localFile.id}`;
        return (
          <GallerySingle
            uid={pageContext.uid}
            path={`${imageSlug}`}
            pageColor={pageColor}
            setPageColor={setPageColor}
            imageEntity={image}
            prevLink={prevLink(index)}
            nextLink={nextLink(index)}
            location={location}
            key={imageSlug}
          />
        );
      })}
    </Router>
  );
}
