import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image/withIEPolyfill";
import { usePalette } from "react-palette";
import Layout from "../components/Layout";
import TransitionLink from "../components/TransitionLink/gallerySingle";

const GallerySingle = ({
  uid,
  imageEntity,
  pageColor,
  setPageColor,
  location,
  nextLink,
  prevLink
}) => {
  const palette = usePalette(imageEntity.image.thumbnail.src);

  useEffect(() => {
    if (Object.keys(palette.data).length === 0) return;
    const shades = Object.keys(palette.data);
    const shade = Math.floor(Math.random() * shades.length);
    const color = palette.data[shades[shade]];
    setPageColor(color);
  }, [palette, setPageColor]);

  const nextLinkRef = useRef(null);
  const prevLinkRef = useRef(null);

  const [windowHeight, setWindowHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const topBar = useRef(null);
  const bottomBar = useRef(null);

  useEffect(() => {
    const setHeightProperty = () => {
      const topBarHeight = topBar.current.getBoundingClientRect().height;
      const bottomBarHeight = bottomBar.current.getBoundingClientRect().height;
      setWindowHeight(window.innerHeight);
      setContainerHeight(window.innerHeight - (topBarHeight + bottomBarHeight));
    };
    setHeightProperty();
    window.addEventListener("resize", setHeightProperty);
    return () => {
      window.removeEventListener("resize", setHeightProperty);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp, true);
    return () => document.removeEventListener("keyup", handleKeyUp, true);
  }, [nextLinkRef, prevLinkRef]);

  const handleKeyUp = event => {
    switch (event.key) {
      case "ArrowRight":
        if (nextLinkRef.current) {
          nextLinkRef.current.click();
        }
        break;
      case "ArrowLeft":
        if (prevLinkRef.current) {
          prevLinkRef.current.click();
        }
        break;
      default:
        break;
    }
  };

  return (
    <Layout
      pageColor={pageColor}
      seoTitle={imageEntity.title1 || "Gallery Image"}
      location={location}
      simple
    >
      <div style={{ height: `${windowHeight}px` }}>
        <div className="h-full">
          <div ref={topBar} className="pt-6 pb-6 md:pb-10 flex">
            <div className="flex justify-center md:justify-start">
              <TransitionLink
                to={`/${uid}`}
                className="flex items-center text-base transition-opacity duration-300 hover:opacity-50 text-white"
              >
                <span className="inline-block w-24 border border-px mr-4 border-white"></span>
                Back
              </TransitionLink>
            </div>
          </div>
          <div style={{ height: `${containerHeight}px` }} className="relative">
            <Img
              fluid={{
                ...imageEntity.image.large,
                aspectRatio: 0
              }}
              alt={imageEntity.image.name || imageEntity.title1}
              className="h-full mx-auto max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl"
              objectFit="contain"
            />
          </div>
          <div
            ref={bottomBar}
            className="flex justify-between pb-6 pt-6 md:pt-10 text-base"
          >
            <TransitionLink
              to={prevLink}
              className="text-white leading-none hover:opacity-50 transition-opacity duration-300"
              ref={prevLinkRef}
            >
              Previous
            </TransitionLink>
            <TransitionLink
              to={nextLink}
              className="text-white leading-none hover:opacity-50 transition-opacity duration-300"
              ref={nextLinkRef}
            >
              Next
            </TransitionLink>
          </div>
        </div>
      </div>
    </Layout>
  );
};

GallerySingle.propTypes = {
  location: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  imageEntity: PropTypes.object.isRequired,
  pageColor: PropTypes.string.isRequired,
  setPageColor: PropTypes.func.isRequired,
  nextLink: PropTypes.string.isRequired,
  prevLink: PropTypes.string.isRequired
};

export default GallerySingle;
